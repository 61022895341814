import React, { useState } from "react";
import { useUiTranslationQuery } from "../hooks/useUiTranslationQuery";

const SearchGameBar = ({ language, onSearch }) => {
    const translation = useUiTranslationQuery();
    const translationText = translation.find(
      (translation) => translation.frontmatter.language === language
    ).frontmatter;
    const [inputValue, setInputValue] = useState("");

    const handleSearch = (event) => {
        const newQuery = event.target.value;
        setInputValue(newQuery);
        onSearch(newQuery);
    };

    const clearSearch = () => {
        setInputValue("");
        onSearch("");
    };

    return (
        <div className="w-full flex items-center relative">
            <input
                type="text"
                placeholder={translationText.search_game}
                value={inputValue}
                className="w-4/5 p-2 border border-gray-300 rounded-lg"
                onChange={handleSearch}
            />
            {inputValue && (
                <button 
                    onClick={clearSearch} 
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-300 hover:text-gray-500"
                >
                    <svg
                        fill="none"
                        stroke="currentColor"
                        className="h-5 w-5"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="m3.5676 19.623 16.865-15.246m-16.865 0 16.865 15.246"
                            strokeLinecap="round"
                            strokeWidth="2"
                        />
                    </svg>
                </button>
            )}
        </div>
    );
};

export default SearchGameBar;
