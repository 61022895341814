import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImagesQuery } from "../hooks/useImagesQuery";

const GameKachel = ({ index, game }) => {
  const gameImages = useImagesQuery();
  // const pfad = game.frontmatter.folder + "/images/" + game.frontmatter.cover;
  const pfad = game.frontmatter.folder + "/images/" + game.frontmatter.card;
  
  const gameImage = gameImages.find(image => image.relativePath === pfad) || null;
  
  return (

<div key={index} className="flex flex-col justify-between items-center m-1 border border-gray-300 bg-white rounded-lg max-h-48 md:max-h-52 w-1/2 sm:w-auto md:w-1/2 lg:max-w-sm overflow-hidden">
  <Link to={`./${game.frontmatter.folder}`} className="w-full flex items-start border-b border-gray-300 overflow-hidden">
    {gameImage && (
      <GatsbyImage
        image={gameImage.childImageSharp.gatsbyImageData}
        alt={game.frontmatter.name}
        objectFit="cover"
        className="w-full h-full"
      />
    )}
    {!gameImage && <div>NO Image! {pfad}</div>}
  </Link>
  <div className="w-full text-center mt-2">
    <span className="text-1xl text-gray-900 font-semibold">{game.frontmatter.name}</span>
  </div>
</div>






  );
};

export default GameKachel;
