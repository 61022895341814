import React from "react";
import Header from "./header";
import { useLocation } from "@reach/router";
import HeadIcon from "./headIcon1";
import CategoryImage from "./buttonImage";
import { Link } from "gatsby";
import LangChooser from './langChooser';

const MainHeader = ({ language, slug }) => {
  const location = useLocation();
  const isRootPath = location.pathname.length <= 4;

  const pathToInfos = '/infos';

  const getLanguage = (language) => {
    if (typeof window !== "undefined") {
      return language || localStorage.getItem("preferredLanguage");
    } else {
      return language || "en";
    }
  };
  const activeLanguage = getLanguage(language);

  //const headerIcon = isRootPath ? "logo" : "0";
  const headerIcon = "logo"

  return (
    <header className="text-zinc-200 text-b">
      <nav className="sticky top-0 px-1 py-0 flex items-stretch bg-gradient-to-b from-yellow-200 to-yellow-100 rounded-b-lg border-b mx-auto max-w-4xl">
      <a href={`/${activeLanguage}/`} className="flex justify-start items-center p-0 gap-0 md:gap-2 no-underline">
        <HeadIcon icon={headerIcon} />
        <div className="flex items-center text-2xl sm:text-3xl font-bold my-0">
          <span className="text-blue-300">over</span>
          <span className="text-blue-600">Rules</span>
        </div>
      </a>


        <div className="flex flex-1 items-center p-2 gap-2 justify-end">
        <Link to={`/${activeLanguage}${pathToInfos}`} 
            className="flex-shrink justify-end min-w-0 max-w-[75px] md:max-w-[85px] cursor-pointer p-1 h-full "
            
          >
            <CategoryImage category="T_tutorial" />
          </Link>
          {activeLanguage && (
            // <Header activeLanguage={activeLanguage} slug={slug} />
            <LangChooser activeLanguage={activeLanguage} slug={slug}></LangChooser>
          )}
        </div>
      </nav>
    </header>
  );
};

export default MainHeader;
