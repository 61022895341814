import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Flag from "./langChooser_flag";

const LangChooser = ({ activeLanguage, slug }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            language
            folder
            listgame
            create_page
          }
        }
      }
    }
  `);
  const languages = Array.from(
    new Set(
      data.allMarkdownRemark.nodes
        // Optional: Filter nodes by slug if slug is not undefined
        .filter((node) => (slug ? node.frontmatter.folder === slug : true))
        //Filter if node.frontmatter.listgame === true
        //.filter(node => node.frontmatter.listgame === true)
        .filter((node) => node.frontmatter.create_page === true)
        .map((node) => node.frontmatter.language)
    )
  ).filter((lang) => lang !== activeLanguage);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);
  const closePopup = () => setIsPopupOpen(false);
  //const openPopup = () => setIsPopupOpen(true);

  const linkTo = slug !== undefined ? `/${slug}/` : "";

  return (
    <div className="relative w-12 sm:w-12 flex justify-end">
      {languages.length === 0 && (
        <div className="relative">
          <Flag lang={activeLanguage} />
          <div className="absolute inset-0  opacity-40 rounded-md "></div>
        </div>
      )}
      {languages.length !== 0 && (
        <button onClick={togglePopup} onKeyDown={togglePopup}>
          <Flag lang={activeLanguage} />
        </button>
      )}

      {isPopupOpen && (
        <div
          className="absolute inset-0 bg-white bg-opacity-20 sm:mt-4 z-10"
          onClick={closePopup}
          onKeyDown={closePopup}
          role="button"
          tabIndex={0}
          aria-label="Close language selector"
        >
          <div className="bg-yellow-100 shadow-xl rounded-lg -m-2 p-2 top-12 md:top-14 z-10 absolute">
            <div className="flex flex-wrap justify-center">
              {languages.length !== 0 &&
                languages.map((lang) => (
                  <a
                    href={`/${lang}${linkTo}`}
                    key={lang}
                    className="flex w-full justify-center text-gray-800 bg-transparent hover:bg-gray-100 z-10"
                    // w-1/2 = 2 Spalten
                  >
                    <Flag lang={lang} size="3" />
                  </a>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LangChooser;
