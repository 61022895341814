
import { useStaticQuery, graphql } from "gatsby"

export const useImagesQuery = () => {
    const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {sourceInstanceName: {eq: "gameimages"}, extension: {in: ["png", "jpg", "gif", "webp", "svg"]}}
      ) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)



    return data.allFile.nodes;
}
