import { useStaticQuery, graphql } from "gatsby";

/**
 * Achtung! Diese .md Files liegen hier:
 * 
 * *****   /content/games/[spiel]/[spiel]-de.md  *****
 * 
 */

export const useGameListQuery = () => {
  //sort: [{ frontmatter: { prio: DESC } }, { frontmatter: { name: ASC } }]
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {frontmatter: {type: {eq: "game"}, listgame: {eq: true}}}
        sort: [{ frontmatter: { prio: DESC } }, { frontmatter: { name: ASC } }]
      ) {
        nodes {
          frontmatter {
            type
            name
            language
            folder
            cover
            card
            author
            listgame
            create_page
            prio
          }
          rawMarkdownBody
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes;
};
// query {
//     allMarkdownRemark(filter: {frontmatter: {type: {eq: "game"}}}) {
//         nodes {
//           frontmatter {
//                 name
//                 language
//                 folder
//                 cover
//                 author
//                 active

//           }
//           rawMarkdownBody
//         }
//       }
//   }
