import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useGameListQuery } from "../hooks/useGameListQuery";
import GameKachel from "../components/gameKachel";
import SearchGameBar from "../components/searchGameBar";
import Layout from "../components/layout";
import { useUiTranslationQuery } from "../hooks/useUiTranslationQuery";

const MainPage = ({ pageContext }) => {
  const { language, headline, subline, html } = pageContext;
  const games = useGameListQuery();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("preferredLanguage", language);
    }
  }, [language]);

  const filterGamesByLanguageAndActive = (games, language) =>
    games.filter(
      (game) =>
        game.frontmatter.language === language &&
        game.frontmatter.listgame === true
    );

  const filterGamesBySearchText = (games, searchText) =>
    games.filter((game) =>
      game.frontmatter.name.toLowerCase().includes(searchText.toLowerCase())
    );

  const filteredGames = filterGamesBySearchText(
    filterGamesByLanguageAndActive(games, language),
    searchText
  );

  return (
    <Layout language={language} pageTitle="Overrules Gamelist">
      <h1 className="text-xl mb-2 font-bold text-blue-900">{headline}</h1>
      <h2 className="text-xl mb-2">{subline}</h2>
      <div
        className="text-base mb-2"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <SearchGameBar onSearch={setSearchText} language={language} />
      <div className="flex justify-center w-full mt-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 max-w-[95%] mx-auto">
          {filteredGames.map((game, index) => (
            <GameKachel key={index} game={game} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

MainPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default MainPage;

export const Head = ({ pageContext }) => {
  const { language } = pageContext;
  const translation = useUiTranslationQuery();

  const translationText = translation.find(
    (translation) => translation.frontmatter.language === language
  ).frontmatter;

  return (
    <>
      <html lang={language}>
        <meta
          name="description"
          content={`${translationText.meta_main}`}
        ></meta>
      </html>
      <title>{`${translationText.title_main}`}</title>
    </>
  );
};
