import React from 'react';
import LangChooser from './langChooser';

// nicht mehr relevant. Habs direkt in main_header.js eingebaut

const Header = ({ activeLanguage, slug }) => {

  return (
<div>
  {/* Language-Bereich */}
  <LangChooser activeLanguage={activeLanguage} slug={slug}></LangChooser>
  

  {/* Burgermenü-Bereich */}
  {/* <div className=" justify-between items-center space-x-2 ">
  <button type="button" className="bg-yellow-200 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 
  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
      <svg className="h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
      </svg>
    </button>
    </div> */}
  
</div>

  );
};

export default Header;
