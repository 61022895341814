import React from 'react';
import MainHeader from './main_header';

const Layout = ({ children, language,slug, pageTitle = 'Overrules' }) => {
  const fullTitle = pageTitle.endsWith('Overrules') ? pageTitle : `${pageTitle} - Overrules`;

  return (
    <div>
      <head>
        <title>{fullTitle}</title>
      </head>
      <MainHeader language={language} slug={slug} />
      <main className="mx-auto max-w-4xl bg-gradient-to-r to-zinc-100 from-zinc-50 shadow-lg rounded-lg p-4">
        {children}
      </main>
    </div>
  );
};

export default Layout;